import React, { useState } from "react"
import { navigate } from 'gatsby'
import { Layout } from '../../components/Layout'
import NetlifyForm from 'react-ssg-netlify-forms'
import './contact.css'

const ContactPage = () => {

  // Pre-Submit for validations and disabling button
  const [processing, setProcessing] = useState(false)
  const preSubmit = async () => {

    if (formValues.name.length > 0 && formValues.email.length > 0) {
      setProcessing(true)
      // Wait 2 seconds to simulate async delay (maybe user confirmation? or
      // external checks?)
      await (new Promise(resolve => setTimeout(resolve, 2000)))
      return true
    }
    else {
      return false
    }
  }

  // Post-Submit for navigating to 'Thank You' page .. or maybe displaying 'sent!'
  // text; totally up to you!
  const postSubmit = () => {
    console.log('Sent!')
    setProcessing(false)
    navigate('/apply/thanks')
  }

  // Simple controlled form setup
  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    age: '',
    movein: '',
    moveout: '',
    social: '',
    job: '',
    pets: '',
    hobbies: '',
    egg: '',
    additional: '',
  })

  return (
    <Layout
      bodyClass={`page-template-default page has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <article className="p-5 card sm:p-10">
        <header className="blog-header has-text-align-center header-footer-group">
          <div className="blog-header-inner section-inner medium">
            <h1 className="entry-title">
              Apply
            </h1>
          </div>
        </header>
        <div className="contact-page">
          <div className="contact-form w-full max-w-lg">
            <NetlifyForm
              formName="Apply"
              formValues={formValues}
              preSubmit={preSubmit}
              postSubmit={postSubmit}
              automaticHoneypot={true}
              className="w-full max-w-lg"
            >

              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'name'}>
                    Your Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'text'}
                    name={'name'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.name}
                    id={'name'}
                    required={true}
                  />
                </div>
                <div className="field w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'email'}>
                    Your Email
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'email'}
                    name={'email'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.email}
                    id={'email'}
                    required={true}
                  />
                </div>
                <div className="field w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'room'}>
                    Which room?
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'room'}
                    name={'room'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.room}
                    id={'room'}
                    required={true}
                  />
                </div>
              </div>

              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'age'}>
                    Your Age
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'number'}
                    name={'age'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.age}
                    id={'age'}
                    required={true}
                  />
                </div>
                <div className="field w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'movein'}>
                    Move-In Date
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'date'}
                    name={'movein'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.movein}
                    id={'movein'}
                    required={true}
                  />
                </div>
                <div className="field w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'moveout'}>
                    Move-Out Date
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'date'}
                    name={'moveout'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.moveout}
                    id={'moveout'}
                    required={true}
                  />
                </div>
              </div>

              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'social'}>
                    Social Media Links
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'text'}
                    name={'social'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.social}
                    id={'social'}
                    required={false}
                  />
                </div>
                <div className="field w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'job'}>
                    Your occupation
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'text'}
                    name={'job'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.job}
                    id={'job'}
                    required={false}
                  />
                </div>
                <div className="field w-full md:w-1/3 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'pets'}>
                    Do you have pets?
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'text'}
                    name={'pets'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.pets}
                    id={'pets'}
                    required={false}
                  />
                </div>
              </div>

              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'hobbies'}>
                    Top 3 Hobbies
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'text'}
                    name={'hobbies'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.hobbies}
                    id={'hobbies'}
                    required={false}
                  />
                </div>
                <div className="field w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'egg'}>
                    If dared, would you eat a raw egg?
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'text'}
                    name={'egg'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.egg}
                    id={'egg'}
                    required={false}
                  />
                </div>
              </div>

              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3">
                  <label className="label block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'additional'}>
                    Additional info:
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'textarea'}
                    name={'additional'}
                    onChange={handleChange}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    value={formValues.additional}
                    id={'additional'}
                    required={false}
                  />
                </div>
              </div>

              {/* RECAPTCHA */}
              <div data-netlify-recaptcha="true"></div>

              {/* SEND BUTTON */}
              <div className="field">
                <button
                  className="button is-link"
                  type="submit"
                  disabled={processing}
                >
                  SEND
                </button>
              </div>
            </NetlifyForm>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default ContactPage
